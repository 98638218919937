export const usePresentation = (city) => {
  const slides = [];

  for (let i = 1; i <= 6; i++) {
    slides.push({
      id: i,
      title: `${city.title}-${i}`,
      src: `https://histrf.ru/files/presentation/${city.dir}/${i}.jpg`,
    });
  }

  return slides;
};
