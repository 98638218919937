export default [
  { id: 1, title: 'Алдан', dir: 'aldan', model_type: 'presentation' },
  { id: 2, title: 'Барнаул', dir: 'barnaul', model_type: 'presentation' },
  { id: 3, title: 'Боровичи', dir: 'borovichi', model_type: 'presentation' },
  { id: 4, title: 'Челябинск', dir: 'chelyabinsk', model_type: 'presentation' },
  {
    id: 5,
    title: 'Екатеринбург',
    subtitle: 'Свердловск',
    dir: 'ekaterinburg',
    model_type: 'presentation',
  },
  { id: 6, title: 'Ижевск', dir: 'izhevsk', model_type: 'presentation' },
  { id: 7, title: 'Казань', dir: 'kazan', model_type: 'presentation' },
  { id: 8, title: 'Кемерово', dir: 'kemerovo', model_type: 'presentation' },
  { id: 9, title: 'Коломна', dir: 'kolomna', model_type: 'presentation' },
  { id: 10, title: 'Кострома', dir: 'kostroma', model_type: 'presentation' },
  {
    id: 11,
    title: 'Мончегорск',
    dir: 'monchegorsk',
    model_type: 'presentation',
  },
  {
    id: 12,
    title: 'Нижний Новгород',
    subtitle: 'Горький',
    dir: 'nizninovgorod',
    model_type: 'presentation',
  },
  {
    id: 13,
    title: 'Нижний Тагил',
    dir: 'niznitagil',
    model_type: 'presentation',
  },
  { id: 14, title: 'Норильск', dir: 'norilsk', model_type: 'presentation' },
  {
    id: 15,
    title: 'Новокузнецк',
    dir: 'novokuzneck',
    model_type: 'presentation',
  },
  {
    id: 16,
    title: 'Новосибирск',
    dir: 'novosibirsk',
    model_type: 'presentation',
  },
  { id: 17, title: 'Омск', dir: 'omsk', model_type: 'presentation' },
  { id: 18, title: 'Пермь', dir: 'perm', model_type: 'presentation' },
  {
    id: 19,
    title: 'Самара',
    subtitle: 'Куйбышев',
    dir: 'samara',
    model_type: 'presentation',
  },
  { id: 20, title: 'Саратов', dir: 'saratov', model_type: 'presentation' },
  {
    id: 21,
    title: 'Северодвинск',
    dir: 'severodvinsk',
    model_type: 'presentation',
  },
  { id: 22, title: 'Уфа', dir: 'ufa', model_type: 'presentation' },
  { id: 23, title: 'Ульяновск', dir: 'ulyanovsk', model_type: 'presentation' },
  { id: 24, title: 'Ярославль', dir: 'yaroslavl', model_type: 'presentation' },
];
